:root {
    --primary-color: #0a2240;
    --mediumGrey: #fafafa;
    --gray: #EFF2F5;
    --lightBlue: #afe2e3;
    --primary: #0a2240;
    --darkGray: #dddddd;
    --white: #fff;
    --black: #000;
    --mediumGrey: #fafafa;
    --gray: #EFF2F5;
    --lightBlue: #afe2e3;
    --primary: #0a2240;
    --darkGray: #dddddd;
    --white: #fff;
    --black: #000;
}
 * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
 }
  
  
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;700;900&display=swap');

.rtl {
    direction: rtl;
  }
  
  .px-1 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .px-2 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .px-3 {
    padding-right: 13px !important;
    padding-left: 13px !important;
  }
  .px-4 {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
  .px-5 {
    padding-right: 23px !important;
    padding-left: 23px !important;
  }
  .py-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .py-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .py-3 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .py-4 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .py-5 {
    padding-top: 23px !important;
    padding-bottom: 23px !important;
  }
  .pb-1 {
    padding-bottom: 5px !important;
  }
  .pb-2 {
    padding-bottom: 8px !important;
  }
  .pb-3 {
    padding-bottom: 13px !important;
  }
  .pb-4 {
    padding-bottom: 18px !important;
  }
  .pb-5 {
    padding-bottom: 23px !important;
  }
  .pt-1 {
    padding-top: 5px !important;
  }
  .pt-2 {
    padding-top: 8px !important;
  }
  .pt-3 {
    padding-top: 13px !important;
  }
  .pt-4 {
    padding-top: 18px !important;
  }
  .pt-5 {
    padding-top: 23px !important;
  }
  .mx-1 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-2 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .mx-3 {
    margin-right: 13px !important;
    margin-left: 13px !important;
  }
  .mx-4 {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
  .mx-5 {
    margin-right: 23px !important;
    margin-left: 23px !important;
  }
  .my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .my-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .my-3 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }
  .my-4 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .my-5 {
    margin-top: 23px !important;
    margin-bottom: 23px !important;
  }
  .mt-1{
    margin-top: 5px !important;
  }
  .mt-2{
    margin-top: 8px !important;
  }
  .mt-3 {
    margin-top: 13px !important;
  
  }
  .mt-4 {
    margin-top: 18px !important;
    
  }
  .mt-5 {
    margin-top: 23px !important;
  }
  .mb-1{
    margin-bottom: 5px !important;
  }
  .mb-2{
    margin-bottom: 8px !important;
  }
  .mb-3 {
    margin-bottom: 13px !important;
  
  }
  .mb-4 {
    margin-bottom: 18px !important;
    
  }
  .mb-5 {
    margin-bottom: 23px !important;
  }
  .border-5 {
    border-radius: 5px !important;
  }
  .border-8 {
    border-radius: 8px !important;
  }
  .border-12 {
    border-radius: 12px !important;
  }
  .border-15 {
    border-radius: 15px !important;
  }
  
  .pointer {
    cursor: pointer;
  }
  .light-shadow {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .add-restaurant-container {
    background-color: var(--lightBlue);
    color: #FFFFFF;
}
.info > li {
    font-size: 1.5rem;
    color: var(--primary-color);
}

.authLeftSide {
    background-color: var(--primary-color);
    min-height: 100vh;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
}

@media only screen and (max-width: 580px) {
    .authLeftSide {
      display: none !important;
    }
  }

.authRightSide {
    min-height: 100vh;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.changeLanguage {
    position: absolute;
    top: 0;
    
    margin-top: 20px;
}

.logo-Login {
    margin-bottom: 40px;
}

.input {
    border-radius: 50px;
    background-color: var(--mediumGrey);
}

.sort-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.iframe-content {
    border: 20px solid #000000;
    width: 100%;
    height: 100%;
  
    border-bottom-right-radius: 2.8rem;
    border-top-right-radius: 2.8rem;
    border-top-left-radius: 2.8rem;
    border-bottom-left-radius: 2.8rem;
  }
  .category-overflow::-webkit-scrollbar {
    width: 1px;
    height: 3px;
  }
  .category-overflow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .category-overflow::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 0.5px solid slategrey;
  }
 
  .summary-bg-gray {
    background-color: #eeeeee !important;
  }
  


h1, h2, h3, h4, h5, h6, p ,a, label, li {
    font-family: 'Cairo', sans-serif;
    margin: 0 ;
    padding: 0 ;
  }
  
  .my-font{
    font-family: 'Cairo', sans-serif !important;
  }
  
  .h1, .h2, .h3, .h4, .h5, .h6, .p, .a, .btn{
    font-family: 'Cairo', sans-serif;
   
  }
  
  h1{
    font-family: 'Cairo', sans-serif;
    font-weight: 600;
  }

  .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    background-color: var(--lightBlue) !important;
    border-color: var(--primary) !important;
  }

  .ant-card-head-title {
    padding: 16px 0 0 0 !important;
}

.ant-table-cell {
    font-family: 'Cairo', sans-serif !important;
}


.ant-layout-sider {
    background: #fff !important;
    border-right: 0.5px solid #e8e8e8;
    overflow: auto;
    min-height: 100vh;
    height: 100%;
    position: fixed !important;
    padding-top: 10px;
  }
  
  
  .ant-layout-header {
    background-color: #ffffff !important;
    border-bottom: 0.5px solid #e8e8e8;
    z-index:1 !important;
  }
  
  .ant-menu-item-selected {
      background-color: rgba(10,34,64, 0.1) !important;
      color: var(--primary-color) !important;
  }
  
  .toggle-bar {
      position: absolute;
      top: 20px;
      z-index: 999;
      padding: 0 10px
      
  }
  
  .ant-tooltip-inner {
    background-color: var(--lightBlue) !important;
  }

  .tables-card-container p {
    margin: 0;
  }
  .tables-card-container > .ant-tabs-card .ant-tabs-content {
    margin-top: -16px;
  }
  .tables-card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px;
    background: #fff;
    border-radius: 5px;
  }
  .tables-card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .tables-card-container > .ant-tabs-card .ant-tabs-tab,
  [data-theme='compact'] .tables-card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  .tables-card-container > .ant-tabs-card .ant-tabs-tab-active,
  [data-theme='compact'] .tables-card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
  }
  #components-tabs-demo-card-top .code-box-demo {
    padding: 24px;
    overflow: hidden;
    background: #f5f5f5;
  }
  [data-theme='compact'] .tables-card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
  }
  [data-theme='dark'] .tables-card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  [data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
  }
  [data-theme='dark'] .tables-card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #141414;
  }
  [data-theme='dark'] .tables-card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #141414;
    border-color: #141414;
  }

  .row-served {
    background-color: var(--darkGray);
    position: relative;
  }
  .row-served::after {
    position: absolute;
    left: 0;
    top: 48%;
    height: 4px;
    background: rgba(165, 96, 96, 0.4);
    content: "";
    width: 100%;
    display: block;
  }
  
  .ant-typography {
    font-family: 'Cairo', sans-serif !important;
  }
  .ant-table-cell{
    font-family: 'Cairo', sans-serif !important;
  }
  .ant-tag {
    font-family: 'Cairo', sans-serif !important;
  }
  .upload-text {
    font-family: 'Cairo', sans-serif !important;
  }